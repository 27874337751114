.bodyContainer {
  padding: 30px 20px;
}
.loginTitle {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 13px;
}
.loginTitleMob {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}
.loginUsingGoogleAccount {
  text-align: center;
  padding-bottom: 23px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.continueWithGoogleButton {
  padding: 9px 0px;
  width: 100%;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.continueWithGoogleButton:hover {
  border: 1px solid #a1e6f6;
}
.continueWithGoogleButtonMob {
  padding: 9px 0px;
}
.continueWithGoogleButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.googleIcon {
  margin-right: 8px;
  margin-bottom: -5px;
}
.metaIcon {
  margin-right: 8px;
  margin-bottom: -2px;
}
.xIcon {
  margin-left: 8px;
  margin-bottom: -3px;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
  margin-top: 15px;
  text-align: center;
}
.orContainer {
  color: var(--white, #fff);
  font-family: "Metrophobic";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 15px;
  padding-top: 20px;
  text-align: center;
}
