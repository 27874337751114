.popUpContent {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-family: "metrophobic";
  font-weight: 400;
  color: #ffffff;
  margin-top: 45px;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.popUpButtonDiv {
  margin-right: 21px;
  margin-left: 21px;
  margin-top: 45px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
}
.cancelButton,
.continueButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 35px;
  color: #99b2c6;
  margin-right: 15px;
  border-radius: 4px;
  cursor: pointer;
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  color: #fd5252;
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  width: 50%;
  height: 35px;
  color: #99b2c6;
  border-radius: 4px;
  cursor: pointer;
}
.continueButtonMob {
  color: #fff;
}
.continueButton:hover {
  color: #fff;
}
