@import "~@openfonts/museomoderno_vietnamese/index.css";
.headerMainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 106px;
  padding-left: 35px;
  padding-right: 50px;
  border-bottom: 1px solid #343339;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  background: #081527;
  z-index: 1;
}
.headerMainContainerMob {
  padding-left: 20px;
  padding-right: 20px;
}
.tetherxLogo {
  width: 170px;
  cursor: pointer;
}
.tetherxLogoMob {
  width: 120px;
  height: 25px;
}
.logoContainer {
  display: flex;
  gap: 3px;
}
.docsButton {
  margin-top: 8px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
  background: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
.docsButtonMob {
  margin-top: 5px;
  font-size: 18px;
  padding-right: 10px;
}
.ifLogin {
  margin-top: 1px;
}
.ifLoginMob {
  padding-right: 45px;
}
.docsButton:hover {
  color: #a1e6f6;
}
.sendButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #a1e6f6;
  background: transparent;
  border: none;
  padding-right: 30px;
  cursor: pointer;
}
.sendButtonMob {
  font-size: 15px;
  padding-right: 15px;
}
.loginButton {
  border: 2px solid #a1e6f6;
  border-radius: 20px;
  background: transparent;
  margin-left: 30px;
  padding: 7px 32px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}

.loginButtonMob {
  border-radius: 16px;
  padding: 3px 15px;
  font-size: 15px;
  margin-left: 0px;
}
.loginButton:hover {
  color: #a1e6f6;
}
.logoutButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-right: 5px;
  margin-top: 3px;
  cursor: pointer;
}
.logoutButton:hover .logoutIcon {
  -webkit-transform: scale(1);
  transform: scale(0.8);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.profileEmailContainer {
  border-radius: 5px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f9fafa;
  margin-left: 20px;
  display: flex;
}
.connectWalletContainer {
  display: flex;
}
.profileEmail {
  padding: 5px 10px;
  font-size: 20px;
  line-height: 13px;
}
.profileEmailMob {
  padding: 6px 10px;
}
.settingsButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 12px;
  cursor: pointer;
}

.hamburgerMenuClose {
  z-index: 10;
  background: transparent;
  border: none;
}
.hamburgerButton {
  background: transparent;
  border: none;
}

.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
}
.popupContent {
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.walletButton {
  display: flex;
  justify-content: flex-end;
}
