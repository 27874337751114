.enterWalletAddress {
  padding: 19px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.enterWalletAddressMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.walletAddressMainDiv {
  padding: 32px 32px 25px 32px;
  width: 540px;
}
.walletAddressMainDivMob {
  width: auto;
  padding: 22px 16px 25px 16px;
}
.fillWalletAddress {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  margin-bottom: 28px;
}
.fillWalletAddressMob {
  border-radius: 5px;
  margin-bottom: 18px;
}
.walletAddressKey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #99b2c6;
  padding-left: 27px;
  padding-top: 15px;
  padding-bottom: 4px;
}
.walletAddressKeyMob {
  padding-left: 16px;
  padding-top: 12px;
  font-size: 16px;
  line-height: 22px;
}
.walletAddressValue {
  padding-left: 27px;
  padding-bottom: 15px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
}
.nickNameValue {
  padding-left: 27px;
  padding-bottom: 15px;
}
.nickNameValueMob {
  padding-left: 16px;
}
.nickName {
  font-size: 18px;
  line-height: 22px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}
.nickNameMob {
  font-size: 15px;
  line-height: 19px;
  padding: 0;
}
.walletAddressValueMob {
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  padding-right: 16px;
}
.inputValue {
  color: #ffffff;
}
.infoDiv {
  margin-bottom: 15px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  border-radius: 10px;
}
.attentionDiv {
  padding-left: 27px;
  padding-top: 15px;
  padding-bottom: 7px;
}
.attentionDivMob {
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.attention {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
  padding-left: 8.5px;
}
.attentionMob {
  font-size: 18px;
  line-height: 22px;
}
.note {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 27px;
  padding-bottom: 20px;
  padding-right: 23px;
}
.noteMob {
  padding-left: 16px;
  font-size: 16px;
  line-height: 21px;
  padding-right: 4px;
  padding-bottom: 18px;
}
.proceedButtonDiv {
  display: flex;
  justify-content: center;
}
.backButton {
  margin-right: 20px;
}

.backButton,
.proceedButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 0px;
  width: 50%;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}
.backButton:hover {
  color: #fd5252;
}
.backButtonMob {
  font-size: 19px;
  line-height: 23px;
  width: 50%;
  color: #fd5252;
}
.proceedButtonDivMob {
  justify-content: space-between;
}
.proceedButtonMob {
  font-size: 19px;
  line-height: 23px;
  padding: 6px 15px;
  width: 50%;
  color: #fff;
  stroke: #fff;
}
.proceedButton:hover {
  color: #fff;
  stroke: #fff;
}
input {
  width: 100%;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.purchaseDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 28px;
  padding-bottom: 25px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}
.purchaseDivMob {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
  line-height: 17px;
  padding-bottom: 20px;
}
.maticImgandText {
  display: flex;
}
.matic {
  padding-left: 5px;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  color: #fd5252;
  margin-top: 10px;
  margin-left: 27px;
  margin-bottom: 15px;
}
.errorMessageMob {
  font-size: 15px;
  margin-left: 0px;
}

.checklist {
  display: flex;
  align-items: center;
  padding-bottom: 28px;
}
.checklistMob {
  padding-top: 25px;
  justify-content: center;
}
.checkbox-label {
  margin-left: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  margin-top: -6px;
  color: #ffffff;
}

.checkbox-input {
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  outline: none;
  margin: 0;
  cursor: pointer;
  position: relative;
  background: #2b3441;
}

.checkbox-input:checked {
  background: #a1e6f6;
}

.checkbox-input:checked::before {
  content: "";
  content: "";
  display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 13px;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
}

.nickName::placeholder {
  color: #99b2c6;
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: 992px) {
    font-size: 15px;
    line-height: 19px;
  }
}
