.sendRUD {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  padding-left: 20px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.sendRUDMob {
  padding-left: 16px;
  margin-bottom: 16px;
  padding-bottom: 12px;
}
.sendRUDMainContainer {
  padding: 38px 32px 25px 32px;
  width: 500px;
}
.sendRUDMainContainerMob {
  padding: 15px 16px;
  width: auto;
}
.sendRUDDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sendingRUD {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.sendingRUDValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dollarSymbol,
.INRSymbol {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #99b2c6;
}
.dollarSymbolMob,
.INRSymbolMob {
  font-size: 22px;
  line-height: 30px;
}

.onEnter {
  color: #ffffff;
}
.inputAmount {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: white;
  width: 100%;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  appearance: none;
}
.inputAmountMob {
  font-size: 22px;
  line-height: 30px;
}

.inputAmount::placeholder {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #99b2c6;
  @media screen and (max-width: 992px) {
    font-size: 22px;
    line-height: 30px;
  }
}
.RUDSending {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.getINRContainer {
  border-radius: 10px;
  border: 1px solid #a1e6f6;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-bottom: 23px;
}
.getINRContainerMob {
  padding-left: 16px;
  margin-bottom: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.getINRAmount {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.getINRAmountValue {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.enterAmountOfUSDWishToSend {
  padding: 19px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
}
.enterAmountOfUSDWishToSendMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 28px;
}
.proceedButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 38px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}
.proceedButtonMob {
  width: 100%;
  color: #fff;
  stroke: #fff;
}
.proceedButton:hover {
  color: #fff;
  stroke: #fff;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
.buttonContainerMob {
  margin-top: 20px;
}
.proceedIcon {
  margin-left: 10px;
}
.newToAppContainer {
  display: flex;
  font-family: "Metrophobic";
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "pnum" on, "lnum" on;
  margin-top: 31px;
}
.newToAppText {
  color: #ffffff;
}
.XXXText {
  color: #ffffff;
  margin-left: 3px;
}
.learnMoreLink {
  margin-left: 21px;
  background: #a1e6f6;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.questionMark {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
}
.USD,
.INR {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 15px;
}
.USDMob,
.INRMob {
  font-size: 22px;
  line-height: 31px;
}
.INRMob {
  margin-left: 5px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
input:focus {
  outline: none;
}
.errorMessageForLogin {
  color: #fd5252;
  margin-left: 20px;
  padding-bottom: 20px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
}
.transactionSuccesffullContainer {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 70px;
  margin-top: 147px;
  width: 446px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 10px;
}
.transactionSuccesffullContainerMob {
  width: 88%;
}
.USDConvertionRate {
  font-family: "Metrophobic";
  font-weight: 200;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.USDConvertionRateMob {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 15px;
  gap: 0px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.errorMessageForLoginMob {
  margin-left: 32px;
  font-size: 15px;
  padding-right: 16px;
}
.spinnerOverlay {
  height: 6vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinnerContainer {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  margin-bottom: 32px;
  margin-top: 20px;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.maxAllowed {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #f9b959;
  padding-bottom: 5px;
}

.bestPrice {
  margin-top: 0px;
  margin-bottom: 0px;
}
.tdsValue {
  margin-top: 0px;
  margin-bottom: 0px;
}
.tdsNote {
  margin-top: 12px;
  margin-bottom: 0px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}
.tdsNoteMob {
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
}
.priceBreakdown {
  display: flex;
  justify-content: space-between;
}
.footer {
  display: flex;
  justify-content: center;
}
.footerContent {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  position: fixed;
  bottom: 30px;
}
.footerFixed {
  position: unset;
  margin-bottom: 30px;
}
.copyright {
  text-align: center;
}
.privacyAndTerms {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.privacyPolicy,
.termsOfUse {
  color: #ffffff;
}
