.transactionSuccessful {
  display: flex;
  justify-content: space-between;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #a1e6f6;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 16px;
  margin-left: 128px;
  margin-right: 30px;
}
.transactionSuccessfulMob {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 22px;
  line-height: 26px;
}
.INDRsDiv {
  margin-left: 40px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.INDRsDivMob {
  margin-left: 25px;
  margin-right: 25px;
}
.firstFlex {
  display: flex;
}
.USDT,
.INDRs {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  padding-left: 5px;
}
.INDRsMob,
.USDTMob {
  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}
.dateAndTime {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
}
.dateAndTimeMob {
  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 34px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
  }
}
.USDTDiv {
  margin-left: 40px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
}
.USDTDivMob {
  margin-left: 25px;
  margin-right: 25px;
}
.successButton {
  padding: 7px 11px;
  border: 1px solid #a1e6f6;
  border-radius: 5px;
  background: #a1e6f6;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.successButtonMob {
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
    line-height: 15px;
    padding: 4px 9px;
  }
}
.transactionDetailsDiv {
  margin-left: 60px;
  margin-right: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.destination,
.key {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.keyMob,
.destinationMob {
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
    padding-bottom: 0px;
  }
}
.value {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.valueMob {
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 12px;
    line-height: 15px;
  }
}
.accountDetails {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-bottom: 59px;
}
.address {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
.addressMob {
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 390px) {
    font-size: 12px;
  }
  @media screen and (max-width: 350px) {
    font-size: 10px;
  }
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.buttonContainerMob {
  margin-left: 25px;
  margin-right: 25px;
}
.walletCheck {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 38px;
  margin-left: 40px;
  margin-right: 40px;
}
.walletCheckMob {
  font-size: 14px;
  line-height: 18px;
  margin-left: 25px;
  margin-right: 25px;
}
.crossIcon,
.crossIcon path {
  @media screen and (max-width: 992px) {
    transform: scale(0.9);
  }
}
.crossIcon:hover path {
  fill: #fd5252;
}
.crossButton {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0px 0px;
}

.transactionDetailsDivMob {
  gap: 0px;
  justify-content: space-between;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 15px;
}
.accountDetailsMob {
  margin-left: 25px;
  margin-bottom: 25px;
}
.statusButtonSuccess {
  padding: 8px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  border: none;
  border-radius: 5px;
  background: #a1e6f6;
}
.statusButtonFailed {
  padding: 8px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background: #fd5252;
}
.statusButtonSuccessMob,
.statusButtonFailedMob {
  font-size: 12px;
  padding: 6px 10px;
}
.statusButtonProcessing {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #f9b959;
  border: none;
  border-radius: 5px;
}
.statusButtonProcessingMob {
  font-size: 12px;
  padding: 6px 10px;
}
.orderDetails {
  margin-left: 60px;
  margin-bottom: 22px;
}
.orderDetailsMob {
  margin-left: 25px;
  margin-bottom: 15px;
}
