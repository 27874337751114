.logoutButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-right: 5px;
  margin-top: 3px;
  cursor: pointer;
}
.logoutButton:hover .logoutIcon {
  -webkit-transform: scale(1);
  transform: scale(0.8);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
