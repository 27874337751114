.failedTransactionMainContainer {
  width: 500px;
  padding: 20px 32px 25px 32px;
}
.failedTransactionMainContainerMob {
  width: auto;
  padding: 20px 16px 0px 16px;
}
.enterOrderId {
  color: #fff;
  font-family: "Metrophobic";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 15px;
}
.enterOrderIdMob {
  font-size: 21px;
}
.fillInputField {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  margin-bottom: 28px;
}
.fillInputFieldMob {
  border-radius: 5px;
  margin-bottom: 18px;
}
.inputkey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #99b2c6;
  padding-left: 27px;
  padding-top: 15px;
  padding-bottom: 4px;
}
.inputkeyMob {
  padding-left: 16px;
  padding-top: 12px;
  font-size: 16px;
  line-height: 22px;
}
.inputValue {
  padding-left: 27px;
  padding-bottom: 15px;
  padding-right: 20px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #ffffff;
}
.inputValueMob {
  padding-left: 16px;
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  padding-right: 16px;
}
.orderId {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.message {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  line-height: 21px;
  background: transparent;
  border: none;
  width: 100%;
  resize: none;
  outline: none;
  overflow: hidden;
}
.proceedButtonDiv {
  margin-top: 40px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.proceedButtonDivMob {
  margin-top: 0px;
  padding-top: 15px;
  padding-bottom: 20px;
}
.proceedButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 0px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
}

.proceedButton:hover {
  color: #fff;
  stroke: #fff;
}
.proceedButtonMob {
  color: #fff;
  stroke: #fff;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
}

.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 0px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  margin-right: 20px;
  width: 50%;
}
.cancelButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
}
.cancelButton:hover {
  color: #fd5252;
}
.supportTicketTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.supportTicketTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  color: #fd5252;
  margin-top: 10px;
  margin-left: 27px;
  margin-bottom: 15px;
}
.errorMessageMob {
  font-size: 15px;
  margin-left: 0px;
}
