.bankAccountTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.bankAccountTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.bankAccountBodyContainer {
  width: 550px;
  padding: 27px 32px 30px 32px;
}
.bankAccountBodyContainerMob {
  width: auto;
  padding: 20px;
}

.bankAccountContent {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  font-size: 18px;
  line-height: 21px;
}

.bankAccountContentMob {
  font-size: 18px;
  line-height: 24px;
}
.addBankAccountButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 10px 41px;
  font-size: 14px;
  line-height: 17px;
}

.addBankAccountButtonMob {
  width: 100%;
  padding: 10px 0px;
  color: #fff;
}
.addBankAccountButton:hover {
  color: #fff;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.buttonContainerMob {
  justify-content: center;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
