.menuBar {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 100%;
  z-index: 2;

  background-color: #121c2b;
}
.MainmenuBar {
  position: fixed;
  width: 100%;
  top: 111px;
  height: 276px;
  background-color: #121c2b;
}
.menuBarEnterActive {
  display: block;
  left: 100%;
}

.menuBarEnter {
  display: block;
  left: 0px;
  transition: 0.5s ease;
}
.menuBarExitActive {
  display: block;
  left: 0px;
}
.menuBarExit {
  display: block;
  left: 100%;
  transition: 0.5s ease;
}
.menuIcon {
  width: 22px;
  height: 22px;
}
.primaryNav {
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style: none;
  margin-top: 20px;
  margin: 0;
  padding-left: 6px;
  li {
    font-family: "Jura";
    font-size: 18px;
  }
  li:hover {
    color: #a1e6f6;
  }
}
.profileNameInMobile {
  margin-top: 16px;
  margin-left: 12px;
}
.mobileButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.mobileButtonsMob {
  justify-content: space-between;
}
.hamburgerButton {
  justify-self: end;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
  width: 39px;
  height: 39px;
  padding: 5px 5px 2px;
  margin-right: 14px;
  margin-left: 12px;
}
.hbWhite {
  border: none;
}

.hbGreen {
  border: none;
  z-index: 3;
}
.mainDivMob {
  width: 100%;
}
.ProfileSecMob {
  top: 19px;
  position: fixed;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
}
.kYCMob {
  position: absolute;
  top: 415px;
  right: 26px;
}
.StatisticsMob {
  display: flex;
  flex-direction: column-reverse;
  gap: 11px;
  position: relative;
  top: 696px;
  font-size: 12px;
  padding: 29px 26px 0 26px;
}
.docButttonMob {
  margin: 745px auto auto;
  padding-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerMob {
  display: flex;
  justify-content: center;
}
.ulDiv {
  padding-left: 26px;
  border-bottom: 1px solid #a5a2b8;
  padding-bottom: 24px;
  width: 100%;
  padding-top: 24px;
}
.profileNameMob {
  margin: auto 0 auto 12px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 100%;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.navBar {
  background: #081527;
  height: 100%;
  padding-top: 120px;
}
.navBar:hover {
  color: #a1e6f6;
}
.navBarItems {
  text-align: center;

  margin-bottom: 20px;
}
.navList {
  list-style-type: none;
  padding: 0;
}

.navLink {
  text-decoration: none;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  color: #a5a2b8;
  padding: 5px 50px;
}
.navLink:hover {
  color: #a1e6f6;
}

.navBarMobile {
  display: flex;
  margin-top: 38px;
}
.activeNavLink {
  color: #a1e6f6;
  background: linear-gradient(90deg, #212d3d 0%, #0e1a2c 100%);
  border-radius: 5px;
}
.profileEmailContainer {
  border-radius: 5px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #f9fafa;
  margin-left: 20px;
  display: flex;
  z-index: 3;
}
.profileEmail {
  padding: 5px 10px;
  font-size: 20px;
  line-height: 25px;
  @media screen and (max-width: 390px) {
    font-size: 17px;
  }
}
.profileEmailMob {
  padding: 6px 10px;
}
.logoutButton {
  background: transparent;
  border: none;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 30px;
  color: #fd5252;
  cursor: pointer;
}
.logoutButtonContainer {
  text-align: center;
  margin-top: 20px;
}
.mainDiv {
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 3;
}
.discordButtonContainer {
  text-align: center;
}
