.myTicketsTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.myTicketsTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.myTicketsContainer {
  width: 550px;
  padding: 42px 19px 37px 19px;
}
.myTicketsContainerMob {
  width: auto;
  padding: 30px 16px 30px 16px;
}
.tickets {
  padding: 15px 20px 25px 20px;
  background: #2e3848;
  margin-bottom: 12px;
  border-radius: 10px;
}
.ticketDetails {
  display: flex;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 12px;
  }
}

.ticketContainer,
.typeContainer,
.statusContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media screen and (max-width: 450px) {
    gap: 2px;
  }
}
.ticketContainer {
  width: 45%;
}
.typeContainer {
  width: 35%;
}
.ticketContainerMob {
  width: 75%;
}
.keys {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.values {
  color: #fff;
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.statusValue {
  font-family: "Metrophobic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.submitted {
  color: var(--yellow, #f3e445);
}
.open {
  color: var(--orange, #f9b959);
}
.resolved {
  color: #a1e6f6;
}
.messageContainer {
  display: flex;
  flex-direction: column;
  width: 72%;
  gap: 4px;
}
.messageContainerMob {
  width: 75%;
}
.messageValue {
  color: #fff;
  font-family: "Metrophobic";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.messageDetails {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  gap: 12px;
}

.backButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 3px 38px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}
.backButton:hover {
  color: #fff;
}
.backButtonMob {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 21px;
}
.typeContainerMob {
  margin-top: 12px;
}
