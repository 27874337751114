.checklistOne,
.checklist {
  display: flex;
  margin-left: 40px;
  margin-right: 38px;
}
.checklistOne {
  padding-bottom: 25px;
}
.checklist {
  padding-bottom: 50px;
}
.checkbox-label {
  margin-left: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: 992px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.checkbox-input {
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  outline: none;
  margin: 0;
  cursor: pointer;
  position: relative;
  background: #2b3441;
  border: 2px solid #a1e6f6;
}

.checkbox-input:checked {
  background: #a1e6f6;
}

.checkbox-input:checked::before {
  content: "";
  content: "";
  display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 13px;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
}
.acceptTerms {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  margin-top: 19px;
  margin-left: 66px;
  margin-right: 66px;
  margin-bottom: 23px;
}
.acceptTermsMob {
  margin-bottom: 40px;
  font-size: 26px;
  line-height: 30px;
  margin-left: 16px;
  margin-right: 16px;
}
.beforeContinuing {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  margin-left: 66px;
  margin-right: 66px;
  margin-bottom: 58px;
}
.beforeContinuingMob {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 25px;
}
.termsOfUse,
.privacyPolicy {
  color: #f9b959;
  text-decoration: none;
}
.cancelButton,
.continueButton {
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  padding: 9px 38px;
  color: #99b2c6;
  cursor: pointer;
  border-radius: 4px;
}
.continueButton:hover {
  color: #fff;
}
.continueButtonMob {
  font-size: 18px;
  width: 50%;
  padding: 9px 0;
  color: #fff;
}
.cancelButton {
  padding: 9px 52px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  color: #99b2c6;
  border-radius: 4px;
  cursor: pointer;
}
.cancelButtonMob {
  font-size: 18px;
  padding: 9px 0;
  width: 50%;
  color: #fd5252;
}
.cancelButton:hover {
  color: #fd5252;
}
.buttonContainer {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 34px;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  color: #fd5252;
  text-align: center;
  margin-bottom: 34px;
  margin-left: 66px;
  margin-right: 66px;
}
.checklistOneMob,
.checklistMob {
  margin-left: 22px;
  padding-bottom: 30px;
  margin-right: 22px;
  font-size: 14px;
}

.buttonContainerMob {
  margin-left: 22px;
  margin-right: 22px;
}
.errorMessageMob {
  font-size: 15px;
  margin-left: 16px;
  margin-right: 16px;
}
.checked {
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
