.yourRecentTransaction {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.noRecentTransactionMob,
.yourRecentTransactionMob {
  font-size: 22px;
  line-height: 26px;
}
.tableHeadings {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
}

td {
  text-align: center;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #ffffff;
  padding: 31px;
}
.historyTableHeader {
  height: 50px;
}

.transactionDetailsContainer {
  display: flex;
  justify-content: space-between;
  gap: 45px;
  padding: 18px 21px;
  background: #2e3848;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 12px;
  border-radius: 10px;
}
.transactionDetailsContainerMob {
  padding: 10px 14px;
  @media screen and (max-width: 380px) {
    margin-bottom: 8px;
  }
}
.amountContainer {
  display: flex;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
}
.amountContainerMob {
  @media screen and (max-width: 425px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media screen and (max-width: 380px) {
    font-size: 15px;
    line-height: 19px;
  }
  @media screen and (max-width: 355px) {
    font-size: 10px;
    line-height: 19px;
  }
}
.recipientContainer {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
}
.recipientContainerMob {
  @media screen and (max-width: 425px) {
    font-size: 14px;
    line-height: 17px;
  }
  @media screen and (max-width: 380px) {
    font-size: 15px;
    line-height: 19px;
  }
  @media screen and (max-width: 355px) {
    font-size: 10px;
    line-height: 18px;
  }
}
.ifOpenPurchaseHistory {
  padding-top: 7px;
}
.dateContainer {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #99b2c6;
}
.dateContainerMob {
  @media screen and (max-width: 425px) {
    font-size: 13px;
    line-height: 17px;
  }
  @media screen and (max-width: 380px) {
    font-size: 12px;
    line-height: 19px;
  }
  @media screen and (max-width: 355px) {
    font-size: 10px;
    line-height: 18px;
  }
}
.indiaFlagIcon {
  margin-right: 6px;
}
.recieverIcon {
  margin-right: 6px;
  @media screen and (max-width: 380px) {
    margin-top: auto;
  }
}
.transferHistory {
  width: 530px;
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  padding-top: 12px;
  padding-bottom: 16px;
}
.transferHistoryMob {
  width: auto;
}
.recieverContainer,
.transferIDContainer {
  display: flex;
  justify-content: space-between;
  color: white;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
}
.transferIDContainer {
  flex-direction: column;
}
.transferIDContainerMob {
  gap: 20px;
  @media screen and (max-width: 500px) {
    gap: 10px;
  }
}
.recieverContainerMob,
.transferIDContainerMob {
  font-size: 18px;
}
.recieverContainer {
  padding-top: 10px;
}
.transferIDContainer {
  margin-top: 18px;
  padding-bottom: 18px;
}
.transferIDContainerMob {
  padding-bottom: 10px;
}
.nameContainer {
  display: flex;
  justify-content: space-between;
  width: 54%;
  padding-bottom: 21px;
}
.nameContainerMob {
  width: 80%;
}
.heading {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  margin-bottom: 6px;
  @media screen and (max-width: 430px) {
    font-size: 14px;
  }
  @media screen and (max-width: 380px) {
    font-size: 12px;
    margin-bottom: 0px;
  }
}
.value {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  @media screen and (max-width: 430px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
}
.transactionDetailsDisplay {
  background: #2e3848;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: -18px;
  margin-bottom: 13px;
  padding-left: 21px;
  padding-right: 21px;
  border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes zoomInAnimation {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
.transactionDetailsDisplayMob {
  padding-left: 14px;
  padding-right: 14px;
}
.fetchHistoryDetails {
  padding-top: 30px;
  padding-left: 40px;
  padding-bottom: 30px;
  @media screen and (max-width: 992px) {
    padding-top: 130px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
  }
}
.transactionDetailsDisplay {
  transition: opacity 0.8s ease-in-out;
}
.USDFlagIcon {
  margin-right: 6px;
}
.transactionOnChainId {
  padding-bottom: 18px;
}
.statusButtonSuccess {
  padding: 8px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  border: none;
  border-radius: 5px;
  background: #a1e6f6;
}
.statusButtonFailed {
  padding: 8px 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  background: #fd5252;
}
.statusButtonSuccessMob,
.statusButtonFailedMob {
  font-size: 12px;
  padding: 6px 10px;
}
.statusButtonProcessing {
  padding: 8px 16px;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #f9b959;
  border: none;
  border-radius: 5px;
}
.statusButtonProcessingMob {
  font-size: 12px;
  padding: 6px 10px;
}
.orderIdContainer {
  margin-top: 15px;
}
.orderIdContainerMob {
  margin-top: 0px;
}
.supportButtonContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
  }
}
.support {
  padding: 8px 26px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #f9b959;
  border: 2px solid rgb(89 89 89 / 30%);
  background: #2b3647;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}
.supportMob {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0px;
}
.spinnerDiv {
  margin-left: 300px;
  margin-top: 0px;
  @media screen and (max-width: 992px) {
    margin-left: 0px;
  }
}
.youHaveNoTransaction {
  padding-top: 25px;
  padding-bottom: 30px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #f9b959;
  padding-left: 32px;
  width: 530px;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.youHaveNoTransactionMob {
  padding-left: 16px;
  padding-top: 18px;
  font-size: 20px;
  line-height: 24px;
  width: auto;
}
.purchaseTitleContainer {
  display: flex;
  justify-content: space-between;
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #a1e6f6;
  padding: 15px 32px;
}
.purchaseTitleContainerMob {
  padding: 9px 16px;
}
.refreshButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 3px 38px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}
.refreshButton:hover {
  color: #fff;
}
.refreshButtonMob {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 21px;
}
.noRecentTransaction {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  padding: 15px 32px;
  border-bottom: 2px solid #a1e6f6;
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  margin: 0;
}
.explorerButton {
  padding: 9px 16px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
  cursor: pointer;
}
.explorerButtonMob {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 18px;
}
.explorerButton:hover {
  color: #fff;
  stroke: #fff;
}
.txOnChainContainer {
  display: flex;
  justify-content: space-between;
}
.txOnChainContainerMob {
  flex-direction: column;
  gap: 12px;
}
.transactionOnChainIdMob {
  padding-bottom: 0px;
}
.buttonContainerMob {
  padding-bottom: 18px;
}
.disabledExplorer {
  color: #808fa5;
  stroke: #808fa5;
  cursor: auto;
}
.disabledExplorer:hover {
  color: #808fa5;
  stroke: #808fa5;
}
.copyIcon {
  width: 30px;
  height: 15px;
}
.copyIcon:hover {
  cursor: pointer;
}

.valueCopyContainer {
  display: flex;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  @media screen and (max-width: 430px) {
    font-size: 12px;
  }
  @media screen and (max-width: 380px) {
    font-size: 10px;
  }
}
