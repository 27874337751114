.serviceTicketSubmittedContent {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin: 40px;
}
.serviceTicketSubmittedContentMob {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
}
.serviceTicketSubmittedTitle {
  margin-top: 60px;
  font-family: "Metrophobic";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #a1e6f6;
}
.serviceTicketSubmittedTitleMob {
  margin-top: 40px;
  font-size: 24px;
}
.continueButton {
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  padding: 9px 52px;
  color: #99b2c6;
  cursor: pointer;
  border-radius: 4px;
}
.continueButtonMob {
  font-size: 20px;
  padding: 9px 0px;
  width: 50%;
  color: #fff;
}
.continueButton:hover {
  color: #fff;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
}
.buttonContainerMob {
  margin-left: 20px;
  margin-right: 20px;
}
