input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
input:focus {
  outline: none;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.profileNameContainer,
.emailContainer {
  font-family: "Metrophobic";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
  color: #99b2c6;
}
.profileNameContainerWeb,
.emailContainerWeb {
  margin-top: 15px;
  margin-left: 22px;
}
.profileNameContainerMob,
.emailContainerMob {
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 0px;
}

.inputName,
.inputEmail {
  padding-left: 22px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 112.4%;
  color: #ffffff;
}
.inputNameWeb,
.inputEmailWeb {
  width: 245px;
  height: 15px;
  padding-bottom: 16px;
}
.inputNameMob,
.inputEmailMob {
  width: 100%;
  height: 45px;
  padding-left: 16px;
  @media only screen and (max-width: 375px) {
    width: 90%;
  }
}
.lastName,
.profileName {
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  color: rgba(153, 178, 198, 1);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  width: 281px;
  line-height: 20px;
}
.profileNameMob {
  width: auto;
  margin-top: 25px;
}
.lastName {
  margin-left: 32px;
}

.lastNameMob {
  width: auto;
  margin-top: 20px;
  margin-left: 0px;
}

.emailWeb {
  display: flex;
  flex-direction: column;
  border: 1px solid #a1e6f6;
  margin-top: 42px;
  border-radius: 10px;
  width: 281px;
}
.emailMob {
  width: auto;
  margin-top: 20px;
  border: 1px solid #a1e6f6;
  border-radius: 10px;
}
.inputName::placeholder,
.inputEmail::placeholder {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: rgba(153, 178, 198, 1);
}
.profileNameEmailContainerWeb {
  display: flex;
  flex-direction: column;
}
.profileNameEmailContainerMob {
  display: flex;
  flex-direction: column;
}
.emailContainer {
  display: flex;
}
.editButton,
.updateProfileButton {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  border-radius: 5px;
  cursor: pointer;
}
.editButton:hover,
.updateProfileButton:hover {
  color: #fff;
}
.backButton:hover {
  color: #fd5252;
}
.editButton {
  padding: 12px 70px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.updateProfileButtonWeb {
  padding: 12px 34px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #99b2c6;
}
.editButtonMob {
  font-size: 16px;
  line-height: 19px;
  padding: 11px 22px;
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 400;
  width: 100%;
}
.updateProfileButtonMob {
  font-size: 16px;
  line-height: 19px;
  padding: 11px 22px;
  color: #fff;
  font-family: "Metrophobic";
  font-weight: 400;
  width: 50%;
}
.updateProfileButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 20px;
}
.updateProfileButtonDivMob {
  margin-top: 30px;
}
.firstFlex {
  display: flex;
}
.secondFlex {
  display: flex;
}
input {
  background: transparent;
  border: none;
}
.phoneNumber {
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-left: 32px;
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  color: rgba(153, 178, 198, 1);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 281px;
}
.emailValidationError {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #fd5252;
  text-align: center;
  padding-bottom: 10px;
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
}
.firstFlexMob {
  flex-direction: column;
}
.secondFlexMob {
  display: block;
}
.phoneNumberMob {
  margin-top: 20px;
  margin-left: 0px;
  width: auto;
}
.idUploadContainer {
  display: flex;
  margin-top: 15px;
}
.idUploadContainerMob {
  flex-direction: column;
  margin-top: 10px;
}
.pancardIdUpload,
.aadharHoldUpload,
.aadharIdUpload {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.aadharHoldUploadMob,
.pancardIdUploadMob,
.aadharIdUploadMob {
  width: 100%;
}

.aadharIdUpload {
  margin-left: 32px;
}
.aadharHoldUploadMob {
  margin-top: 10px;
}
.aadharIdUploadMob {
  margin-top: 20px;
  margin-left: 0px;
}
.idUpload {
  margin-top: 15px;
  margin-left: 22px;
  margin-bottom: 10px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
}
.idUploadMob {
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 20px;
}
.pancard,
.aadhar {
  margin-left: 22px;
  margin-bottom: 15px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: #8d98aa;
}
.pancardMob,
.aadharMob {
  margin-top: 12px;
  margin-right: 16px;
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 20px;
}
.fileAttachDiv {
  display: flex;
  justify-content: center;
}
.fileAttachDivMob {
  justify-content: center;
  margin-left: 0px;
}
.attachment {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 6px;
}

.updateProfileSuccesfully {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 12px;
  color: #a1e6f6;
  margin-top: 10px;
  text-align: center;
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
}
.profileMainContainer {
  padding-left: 32px;
  padding-right: 32px;
}
.profileMainContainerMob {
  padding-left: 20px;
  padding-right: 20px;
}
.disabledInput {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 112.4%;
  color: #8d98aa;
}
.changeFile {
  margin-top: 15px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  @media screen and (max-width: 992px) {
    text-align: center;
    margin-bottom: 10px;
  }
}
.supportedFiles {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 112.4%;
  color: #8d98aa;
  margin-top: 40px;
}
.supportedFilesMob {
  font-size: 16px;
  margin-top: 20px;
}
.spinnerOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerContainer {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.loadingContainer {
  display: flex;
  justify-content: space-between;
}
.loading {
  display: flex;
  margin-top: 15px;
  margin-right: 22px;
  margin-bottom: 10px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  @media screen and (max-width: 992px) {
    margin-top: 12px;
  }
}
.spinner {
  margin-top: 5px;
  margin-left: 4px;
}
.aadharHoldContainer {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.aadharHoldContainerMob {
  margin-top: 20px;
  flex-direction: column;
}
.backButton {
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border: 1px solid rgb(111, 111, 111, 0.3);
  border-radius: 5px;
  cursor: pointer;
  font-family: "Metrophobic";
  font-weight: 400;
}
.backButtonWeb {
  padding: 12px 45px;
  font-size: 18px;
  line-height: 22px;
  margin-right: 20px;
  color: #99b2c6;
}
.backButtonMob {
  font-size: 16px;
  line-height: 19px;
  padding: 11px 22px;
  color: #fd5252;
  width: 50%;
  margin-right: 15px;
}
.holdAadharContent {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #8d98aa;
  margin-top: 10px;
  margin-left: 53%;
}
.holdAadharContentMob {
  margin-left: 0px;
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
}
.holdAadharContentAfterFileUploaded {
  margin-top: 8px;
  @media screen and (max-width: 992px) {
    margin-top: 10px;
  }
}
