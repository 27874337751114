.footer {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
  margin-left: 52px;
}
.footerMob {
  margin-left: 0;
  position: fixed;
  bottom: 40px;
}

.copyright {
  text-align: center;
}
.privacyAndTerms {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.privacyPolicy,
.termsOfUse {
  color: #ffffff;
}
