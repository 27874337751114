.KYCInProgressContainer {
  padding-left: 32px;
  padding-top: 40px;
  padding-right: 32px;
  padding-bottom: 40px;
}
.KYCInProgressContainerMob {
  padding-top: 35px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
}
.KYCApplicationSubmitted {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  background: #a1e6f6;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-bottom: 16px;
}
.KYCApplicationSubmittedMob {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
.submittedAndWaiting {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(249, 185, 89, 1);
}
.yourApplicationIsRejected {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fd5252;
}
.yourAccountIsVerified {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #a1e6f6;
}
.weWillLetYouKnow {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 34px;
}
.weWillLetYouKnowMob {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 30px;
}
.KYCButton {
  background: transparent;
  border: 0.5px solid rgba(255, 255, 255, 1);
  padding: 7px 13px;
  color: #ffffff;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  border-radius: 3px;
}
.KYCButtonMob {
  margin-right: auto;
  margin-bottom: 6px;
}
.statusContainer {
  display: flex;
  gap: 3px;
  margin-top: 28px;
}
.statusContainerMob {
  display: block;
}
.KYCInProgess {
  margin-left: 11px;
  margin-bottom: -3px;
}
.statusOfKYC {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.statusOfKYCMob,
.submittedAndWaitingMob {
  font-size: 16px;
  line-height: 20px;
}

.KYCButtonDiv {
  display: flex;
  justify-content: space-between;
}
.KYCButtonDivMob {
  flex-direction: column;
}
.reasonStatement {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-top: 4px;
  margin-left: -58px;
}
.reasonStatementMob {
  margin-left: 0px;
}
.reSubmitKycButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  padding: 11px 61px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #99b2c6;
  border-radius: 5px;
}
.reSubmitKycButtonMob {
  width: 100%;
  padding: 11px 0px;
  margin-left: 0px;
  margin-top: 25px;
  color: #fff;
}
.reSubmitKycButton:hover {
  color: #fff;
  cursor: pointer;
}
.reSubmitKycButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: 49px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
