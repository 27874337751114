.purchaseInrtTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.purchaseInrtTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.purchaseInrtMainContainer {
  width: 550px;
  padding-bottom: 30px;
}
.purchaseInrtMainContainerMob {
  width: auto;
}
.purchaseContent {
  padding-top: 28px;
  margin-left: 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}
.purchaseContentMob {
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 20px;
  margin-left: 20px;
  padding-top: 18px;
}
.payUSD {
  margin-top: 30px;
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  padding-left: 39px;
  padding-bottom: 15px;
  margin-left: 32px;
  margin-right: 32px;
}
.payUSDMob {
  padding-left: 20px;
  margin-top: 0px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 11px;
}
.dollarSymbol {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.dollarSymbolMob {
  font-size: 24px;
  line-height: 28px;
}
.payCryptoDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payingUSD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
  padding-top: 15px;
}
.payingUSDMob {
  padding-top: 11px;
}

input {
  background: transparent;
  border: none;
  color: #ffffff;
  outline: none;
}

.payingUSDValue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputAmount {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  width: 100%;
}
.inputAmountMob {
  font-size: 24px;
  line-height: 28px;
}
.payingCrypto {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.payingCryptoMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}
.getRUD {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  padding-left: 39px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 30px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 15px;
}
.getRUDMob {
  margin-top: 20px;
  padding-left: 20px;
  padding-top: 10px;
  margin-left: 22px;
  margin-right: 19px;
  border-radius: 5px;
  padding-bottom: 11px;
}
.recievingRUD {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #99b2c6;
  padding-bottom: 4px;
}
.recievingRUDVal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.equivalentRUD {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
}
.equivalentRUDMob {
  font-size: 24px;
  line-height: 28px;
}
.cryptoRUD {
  font-family: "roboto";
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #ffffff;
  margin-right: 39px;
}
.cryptoRUDMob {
  font-size: 24px;
  line-height: 28px;
  margin-right: 16px;
}
.proceedButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 22px;
  width: 50%;
}

.proceedButtonMob {
  padding: 10px 0px;
  color: #fff;
}
.proceedButton:hover {
  color: #fff;
}
.backButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 22px;
  width: 50%;
  margin-right: 20px;
}
.backButton:hover {
  color: #fd5252;
}
.backButtonMob {
  padding: 10px 0px;
  color: #fd5252;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 15px;
  margin-left: 32px;
  margin-right: 32px;
}
.buttonContainerMob {
  justify-content: center;
  margin-left: 20px;
  margin-right: 22px;
}
input::placeholder {
  font-size: 28px;
  line-height: 33px;
  @media screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #fd5252;
  text-align: center;
  padding-bottom: 10px;
}
.errorMessageMob {
  margin-left: 16px;
  margin-right: 16px;
}
