.CardTitle {
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 49px;
}
.CardTitleMob {
  flex-direction: column;
  padding-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 16px;
}
.cardTitleContent {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.cardTitleContentMob {
  text-align: center;
  font-size: 22px;
  @media screen and (max-width: 350px) {
    font-size: 20px;
  }
}
.paymentMethodsLogo {
  display: flex;
  gap: 14px;
}
.paymentMethodsLogoMob {
  justify-content: center;
}
.paymentMethodMainDiv {
  display: grid;
  grid-template-columns: 40% 60%;
}
.paymentMethodMainDivMob {
  display: flex;
  flex-direction: column;
}
.qrCodeSection {
  display: flex;
  flex-direction: column;
  margin: 59px auto 30px auto;
}
.qrCodeSectionMob {
  margin: 30px auto 10px auto;
}
.raritiQRcode {
  height: 220px;
  width: 220px;
}
.raritiQRcodeMob {
  margin: auto;
  height: 120px;
  width: 120px;
}
.scanQRContent {
  padding-top: 7px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}
.scanQRContentMob {
  padding-top: 10px;
  font-size: 20px;
  line-height: 24px;
}
.anotherPaymentMethod {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  margin: auto;
}
.upiTransactionSection {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 70px;
}
.upiTransactionSectionMob {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
}

.payUPI {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  padding-bottom: 20px;
  padding-right: 20px;
}
.payUPIMob {
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 20px;
  line-height: 24px;
}
.sendViaUPI {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.sendViaUPIMob {
  margin-left: 20px;
}
.upiIdDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  border-radius: 10px;
  border: 1px solid #a1e6f6;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 9px 10px;
  margin-bottom: 20px;
}
.upiIdDivMob {
  margin-right: 20px;
  margin-left: 20px;
}
.transactionId {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.transactionIdMob {
  margin-left: 20px;
}
.inputField {
  border-radius: 10px;
  border: 1px solid #a1e6f6;
  margin-top: 14px;
  height: 41px;
}
.inputFieldMob {
  margin-right: 20px;
  margin-left: 20px;
}
.inputAmount {
  padding-top: 10px;
  padding-left: 10px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.disclaimerNote {
  display: flex;
  margin-top: 14px;
  @media screen and (max-width: 992px) {
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.note {
  padding-left: 7px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  @media screen and (max-width: 380px) {
    text-align: justify;
    font-size: 13px;
  }
}
.checkBoxNote {
  padding-left: 28px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  @media screen and (max-width: 992px) {
    text-align: center;
    margin-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 380px) {
    font-size: 12px;
  }
}
.checklist {
  display: flex;
  align-items: center;
  padding-bottom: 28px;
}
.checklistMob {
  padding-top: 25px;
  justify-content: center;
}
.checkbox-label {
  margin-left: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  margin-top: -6px;
  color: #ffffff;
}

.checkbox-input {
  appearance: none;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  outline: none;
  margin: 0;
  cursor: pointer;
  position: relative;
  background: #2b3441;
  border: 1px solid #a1e6f6;
}

.checkbox-input:checked {
  background: #a1e6f6;
}

.checkbox-input:checked::before {
  content: "";
  content: "";
  display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 13px;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
}
.buttonContainer {
  padding-bottom: 20px;
}
.cancelButton,
.paidButton {
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  border-radius: 4px;
  cursor: pointer;
}
.cancelButton {
  padding: 9px 44px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  margin-right: 20px;
  @media screen and (max-width: 1130px) {
    padding: 9px 30px;
  }
  @media screen and (max-width: 1050px) {
    padding: 9px 25px;
    font-size: 18px;
    line-height: 22px;
  }
  @media screen and (max-width: 374px) {
    font-size: 12px;
  }
}
.cancelButton:hover {
  color: #fd5252;
}
.paidButton {
  padding: 9px 24px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  @media screen and (max-width: 1130px) {
    padding: 9px 15px;
  }
  @media screen and (max-width: 1050px) {
    padding: 9px 12px;
    font-size: 18px;
    line-height: 22px;
  }
  @media screen and (max-width: 374px) {
    font-size: 12px;
  }
}

.paidButton:hover {
  color: #fff;
  stroke: #fff;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  background: #081527;
  border-radius: 15px;
  width: 547px;
  height: auto;
  overflow: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.errorMessage {
  margin-bottom: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #fd5252;
}
.timerSection {
  margin-top: 74px;
}
.remainingTimeKey {
  text-align: center;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.timer {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #f9b959;
}
.timerSectionMob {
  margin-top: 0px;
  margin-bottom: 30px;
}
.timerMob {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #f9b959;
}
.remainingTimeKeyMob {
  text-align: center;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.buttonContainerMob {
  display: flex;
  margin-right: 20px;
  margin-left: 20px;
}
.cancelButtonMob {
  padding: 9px 0px;
  width: 50%;
  color: #fd5252;
}
.paidButtonMob {
  padding: 9px 0px;
  width: 50%;
  color: #fff;
  stroke: #fff;
}
.errorMessageMob {
  text-align: center;
  font-size: 15px;
  line-height: 17px;
}
.spinnerContainer {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid rgb(148, 148, 189);
  border-radius: 50%;
  border-top-color: rgb(64, 64, 64);
  animation: spin 1.5s ease-in-out infinite;
  -webkit-animation: spin 1.5s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
.copyIcon {
  width: 30px;
  height: 15px;
}
.copyIcon:hover {
  cursor: pointer;
}
.checked {
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
