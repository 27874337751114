.supportTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.supportTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.supportMainContainer {
  padding: 38px 32px 37px 32px;
  width: 450px;
}
.supportMainContainerMob {
  padding: 25px 16px 10px 16px;
  width: auto;
}
.supportTicketContainer {
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  padding: 26px 18px;
  margin-bottom: 20px;
}
.supportTicketContainerMob {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 20px 16px;
}
.nextIcon {
  padding-right: 12px;
  margin-top: -3px;
}
.nextIconMob {
  padding-right: 0px;
  margin-top: 0px;
}
.proceedButton {
  width: 100%;
  border: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  cursor: pointer;
}
.proceedButton:hover {
  color: #fff;
  stroke: #fff;
}
.proceedButtonMob {
  font-size: 21px;
  color: #fff;
}
.docsLink {
  text-decoration: none;
}
.note {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
.noteMob {
  font-size: 15px;
  line-height: 19px;
}
.supportMail {
  color: #a1e6f6;
}
.supportNumber {
  margin-top: 5px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #ffffff;
  @media screen and (max-width: 992px) {
    font-size: 14px;
    line-height: 19px;
  }
}
