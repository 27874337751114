.imageUpload {
  display: flex;
  justify-content: center;
}
.uploadIcon {
  align-self: center;
  cursor: pointer;
}
.previewImage {
  border-radius: 5px;
  width: 190px;
  height: 115px;
}
.previewDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.previewDivWithoutFile {
  margin-bottom: 80px;
  margin-top: 40px;
}
.dragAndDropText {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 112.4%;
  color: #ffffff;
  align-self: center;
}
.dragAndDropTextInMob {
  display: none;
  @media screen and (max-width: 992px) {
    display: block;
    font-size: 12px;
    line-height: 112.4%;
    margin-top: 11px;
  }
}
.browseLink {
  text-decoration: none;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding-left: 6px;
  cursor: pointer;
}
.errorMessage {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fd5252;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}
.pdfPreview {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #8d98aa;
  text-align: center;
  margin: 45px 20px;
}
