.paymentVerificationDiv {
  margin: 116px auto;
}
.paymentVerificationDivMob {
  margin: 80px auto;
}
.loaderList {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
.loaderList li {
  display: block;
}
.loaderCircle span {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 30px;
  margin: auto;
  height: 32px;
  width: 32px;
  @media screen and (max-width: 992px) {
    right: 10px;
  }
}
.loaderCircle span::before,
.loaderCircle span::after {
  content: "";
  display: block;
  position: absolute;
  margin: auto;
  height: 50px;
  width: 50px;
  border: 3px solid #a1e6f6;
  border-radius: 50%;
  opacity: 0;
  animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
.loaderCircleMob span::before,
.loaderCircleMob span::after {
  height: 40px;
  width: 40px;
}

@keyframes loader-6-1 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1.5);
    opacity: 0;
  }
}
.loaderCircle span::after {
  -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
    infinite;
  animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@keyframes loader-6-2 {
  0% {
    transform: translate3d(0, 0, 0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 0;
  }
}
.verifyingContent {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.verifyingContentMob {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.timer {
  margin-top: 30px;
  margin-bottom: 20px;
}
.errorMessage {
  margin-bottom: 20px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  color: #fd5252;
}
.errorMessageMob {
  text-align: center;
  font-size: 16px;
  line-height: 17px;
}
