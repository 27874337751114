.bankAccountBodyContainer {
  width: 550px;
  padding: 27px 32px 30px 32px;
}
.bankAccountBodyContainerMob {
  width: auto;
  padding: 20px;
}
.bankAccountTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.bankAccountTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.bankAccountContent {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  line-height: 21px;
}
.bankAccountHolder {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
}
.bankAccountHolderMob {
  font-size: 17px;
  line-height: 22px;
}
.bankAccountContentMob {
  font-size: 15px;
  line-height: 19px;
}
.proceedButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 22px;
  width: 100%;
}

.proceedButtonMob {
  padding: 10px 0px;
  color: #fff;
}
.proceedButton:hover {
  color: #fff;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 15px;
}
.buttonContainerMob {
  justify-content: center;
}

.accountNumberValue {
  display: flex;
  border: 1px solid #a1e6f6;
  border-radius: 10px;
  margin-top: 35px;
  margin-bottom: 28px;
  padding: 12px 27px;
}
.accountNumberValueMob {
  padding-left: 16px;
  padding-right: 11px;
  border-radius: 5px;
  margin-bottom: 18px;
  margin-top: 25px;
}
.accountNumber {
  font-size: 20px;
  line-height: 25px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}
.accountNumberMob {
  font-size: 18px;
  line-height: 22px;
  padding: 0;
}
.eyeIcons {
  height: 30px;
  width: 30px;
  color: #ffffff;
}
input::placeholder {
  color: #99b2c6;
  font-size: 20px;
  line-height: 25px;
  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 22px;
  }
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  color: #fd5252;
  text-align: center;
  padding-bottom: 10px;
  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }
}
.exclamationIcon {
  width: 30px;
  height: 30px;
  padding-top: 5px;
  padding-right: 8px;
  @media screen and (max-width: 403px) {
    padding-top: 9px;
  }
}
.alertContainer {
  display: flex;
}
.alertMessage {
  font-size: 16px;
  line-height: 20px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  @media screen and (max-width: 992px) {
    line-height: 35px;
  }
  @media screen and (max-width: 735px) {
    line-height: 21px;
  }
  @media screen and (max-width: 403px) {
    font-size: 14px;
    line-height: 19px;
  }
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 506px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
