.profileButtonDiv {
  padding-left: 32px;
  padding-top: 12px;
  padding-bottom: 10px;
  background: #212c3d;
  border-radius: 10px 10px 0px 0px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
}
.profileButtonDivMob {
  padding-left: 22px;
  padding-top: 11px;
  padding-bottom: 10px;
}
