.acceptTerms {
  display: flex;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  justify-content: center;
  margin-top: 70px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 50px;
}
.acceptTermsMob {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-size: 20px;
  line-height: 26px;
}
.beforeContinuing {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
  margin-left: 66px;
  margin-right: 66px;
  margin-bottom: 58px;
}
.termsOfUse,
.privacyPolicy {
  color: #f9b959;
  text-decoration: none;
}
.cancelButton,
.continueButton {
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.cancelButton,
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  color: #99b2c6;
  cursor: pointer;
  border-radius: 4px;
}
.continueButton {
  padding: 9px 15px;
}
.cancelButton {
  margin-right: 15px;
  padding: 9px 58px;
}
.cancelButtonMob,
.continueButtonMob {
  font-size: 15px;
  padding: 5px 0px;
  width: 50%;
}
.continueButtonMob {
  color: #fff;
}
.cancelButton:hover {
  color: #fd5252;
}
.cancelButtonMob {
  margin-right: 8px;
  color: #fd5252;
}
.continueButton:hover {
  color: #fff;
}

.buttonContainer {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 34px;
}
.buttonContainerMob {
  margin-left: 20px;
  margin-right: 20px;
}
.exclamationIcon {
  width: 30px;
  height: 30px;
  padding-left: 4px;
}
.exclamation {
  width: 50px;
  height: 50px;
}
.exclamationContainer {
  display: flex;
  justify-content: center;
}
.exclamationContainer {
  margin-top: 30px;
}
