.titleContainer {
  background: #202c3c;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #a1e6f6;
}

.bodyContainer {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #202c3c 0%, #101c2e 100%);
}
.rudMainDiv {
  padding-top: 36px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
}
.rudMainDivMob {
  padding-top: 130px;
  padding-left: 20px;
  padding-right: 20px;
}

.appear {
  animation: zoomInAnimation 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes zoomInAnimation {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
