.KYCMainDiv {
  width: 550px;
  padding: 27px 32px 30px 32px;
}
.KYCMainDivMob {
  width: auto;
  padding: 20px;
}
.verifyProfile,
.KYCDiv {
  font-family: "Metrophobic";
  font-weight: 500;
  color: #ffffff;
}
.KYCDiv {
  margin: 0;
}
.verifyProfile,
.KYCDivWeb {
  font-size: 20px;
  line-height: 27px;
}
.KYCDivMob {
  font-size: 18px;
  line-height: 24px;
}
.KYCAMLButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}

.KYCAMLButtonWeb {
  padding: 10px 41px;
  font-size: 18px;
  line-height: 22px;
}
.KYCAMLButtonMob {
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  padding: 10px 0px;
  color: #fff;
}
.KYCAMLButton:hover {
  color: #fff;
}
.verifyProfile {
  padding-top: 5px;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}
.buttonContainerMob {
  justify-content: center;
}
