/* .firebaseui-container {
} */
.firebaseui-idp-button,
.firebaseui-tenant-button {
  padding: 9px 91px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
  background: transparent;
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
  padding: 9px 91px;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.firebaseui-card-header {
  display: none;
}
.firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions
  .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(0, 0, 0, 0.1);
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}