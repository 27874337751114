.titleContainer {
  background: #202c3c;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #a1e6f6;
}

.bodyContainer {
  border-radius: 0px 0px 10px 10px;
  height: auto;
  background: linear-gradient(180deg, #202c3c 0%, #101c2e 100%);
}
