.loginTitle {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 28px;
  line-height: 125%;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-align: center;
  padding-top: 21px;
  padding-bottom: 13px;
}
.loginTitleMob {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}
.emailPswdContainer {
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.emailInputField,
.paswdInputField {
  width: auto;
  border: 1px solid #a1e6f6;
  border-radius: 5px;
}
.paswdInputField {
  display: flex;
  justify-content: space-between;
}
.input {
  padding: 15px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 16px;
  line-height: 112.4%;
  color: #ffffff;
}
.inputWeb {
  width: 93%;
}
.input::placeholder {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 112.4%;
  color: rgba(153, 178, 198, 1);
}
.signInbuttonContainer {
  display: flex;
}
.signInbuttonContainer,
.forgotPasswordContainer {
  text-align: center;
}
.forgotPasswordButton {
  border: none;
  background-color: transparent;
  color: #a1e6f6;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.backButton,
.signInButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  background: #7ca2c2;
  color: #a1e6f6;
  padding: 10px 0px;
  width: 50%;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}
.backButton {
  margin-right: 15px;
}
.backButton:hover {
  color: #fd5252;
}
.signInButton:hover {
  color: #fff;
}
.signInButtonMob {
  color: #fff;
}
.backButtonMob {
  color: #fd5252;
}
.errorMessage {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #fd5252;
  text-align: center;
}
.toggleText {
  text-align: center;
  color: #a1e6f6;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.passwordIcon {
  width: 20px;
  height: 20px;
  margin: auto;
  padding-right: 15px;
}
