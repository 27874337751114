.bankTransferTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.bankTransferTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.bankTransferMainContainer {
  width: 530px;
  padding: 35px 32px;
}
.bankTransferMainContainerMob {
  width: auto;
  padding: 22px 16px;
}
.transferDetailsContainer {
  border: 1px solid #a1e6f6;
  border-radius: 5px;
  padding: 20px;
}

.detailTitles {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #99b2c6;
}
.detailTitlesMob {
  font-size: 17px;
  line-height: 21px;
}
.detailValues {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #ffffff;
}
.detailValuesMob {
  font-size: 18px;
  line-height: 25px;
}
.flexConatiner {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 4px;
}
.copyIcon {
  width: 30px;
  height: 25px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.paidAmount {
  margin-top: 15px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #ffffff;
}
.paidAmountMob {
  font-size: 18px;
  line-height: 22px;
}
.enteredValue {
  margin-top: 15px;
  border: 1px solid #a1e6f6;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 25px;
  font-family: "roboto";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}
.enteredValueMob {
  padding: 12px 16px;
}
.payingINR {
  font-family: "roboto";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}
.payingINRMob {
  font-size: 24px;
  line-height: 28px;
}
.inputValue::placeholder {
  color: #99b2c6;
  font-size: 22px;
  line-height: 26px;
  @media screen and (max-width: 992px) {
    font-size: 18px;
    line-height: 21px;
  }
}
.inputValue {
  font-family: "roboto";
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  color: #ffffff;
}
.proceedButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 22px;
  width: 50%;
}

.proceedButtonMob {
  font-size: 18px;
  padding: 10px 0px;
  color: #fff;
}
.proceedButton:hover {
  color: #fff;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 15px;
}
.backButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 500;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 22px;
  width: 50%;
  margin-right: 20px;
}
.backButton:hover {
  color: #fd5252;
}
.backButtonMob {
  font-size: 18px;
  padding: 10px 0px;
  color: #fd5252;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  color: white;
}
.popupContent {
  background: #081527;
  border-radius: 15px;
  width: 550px;
  height: auto;
  overflow: auto;
  animation: zoomInAnimation 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
@keyframes zoomInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
}
.note {
  margin-top: 20px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: #f9b959;
}
.noteMob {
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}
