.buttonContentDiv {
  width: 500px;
}
.buttonContentDivMob {
  width: auto;
}
.paymentMethodChoose {
  color: #99b2c6;
  font-family: "Metrophobic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding-top: 25px;
  padding-left: 32px;
  padding-right: 10px;
}
.paymentMethodChooseMob {
  font-size: 15px;
  line-height: 18px;
  padding-left: 22px;
  padding-right: 3px;
  padding-top: 20px;
}
.paymentOptionsDiv {
  display: flex;
  padding-top: 15px;
  padding-left: 32px;
}
.paymentOptionsDivMob {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 16px;
  padding-left: 22px;
}

.proceedButtonDiv {
  margin-top: 50px;
  padding-bottom: 20px;
  margin-right: 24px;
  display: flex;
  justify-content: flex-end;
}
.proceedButtonDivMob {
  margin-top: 0px;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-left: 22px;
  margin-right: 19px;
}
.proceedButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 32px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
}

.proceedButton:hover {
  color: #fff;
  stroke: #fff;
}
.proceedButtonMob {
  color: #fff;
  stroke: #fff;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}

.cancelButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 38px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  margin-right: 20px;
}
.cancelButtonMob {
  color: #fd5252;
  font-size: 22px;
  line-height: 27px;
  padding: 7px 0px;
  width: 100%;
}
.cancelButton:hover {
  color: #fd5252;
}
.supportTicketTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.supportTicketTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
