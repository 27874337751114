.acceptTerms {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  margin-top: 70px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 60px;
}
.acceptTermsMob {
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 26px;
}
.beforeContinuing {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  text-align: justify;
  color: #ffffff;
  margin-left: 66px;
  margin-right: 66px;
  margin-bottom: 58px;
}
.termsOfUse,
.privacyPolicy {
  color: #f9b959;
  text-decoration: none;
}
.cancelButton,
.continueButton {
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  padding: 9px 52px;
  color: #99b2c6;
  cursor: pointer;
  border-radius: 4px;
}
.continueButtonMob {
  font-size: 20px;
  padding: 9px 0px;
  width: 50%;
  color: #fff;
}
.continueButton:hover {
  color: #fff;
}
.cancelButton {
  padding: 9px 52px;
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 24px;
  line-height: 123.8%;
  letter-spacing: 0.035em;
  color: #99b2c6;
  border-radius: 4px;
  cursor: pointer;
}
.cancelButtonMob {
  font-size: 20px;
  padding: 9px 0px;
  width: 50%;
  color: #fd5252;
}
.cancelButton:hover {
  color: #fd5252;
}
.buttonContainer {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-bottom: 34px;
}
.buttonContainerMob {
  margin-left: 20px;
  margin-right: 20px;
}
