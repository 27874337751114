.netBankingTitle {
  padding: 10px 32px;
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
}
.netBankingTitleMob {
  padding: 9px 16px;
  font-size: 22px;
  line-height: 26px;
}
.netBankingMainContainer {
  padding-bottom: 35px;
  width: 550px;
  padding-top: 30px;
  padding-left: 35px;
  padding-right: 35px;
}
.netBankingMainContainerMob {
  width: auto;
  padding-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
}
.amountContainer {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-bottom: 35px;
}
.payViaAxis,
.amount {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  text-align: center;
}
.bankIcon {
  width: 32px;
  height: 32px;
  padding-right: 7px;
}
.netBanking {
  display: flex;
}
.netBankingIMPS {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 27px;
  color: #ffffff;
  padding-top: 3px;
}
.netBankingIMPSMob {
  font-size: 19px;
  line-height: 22px;
}
.sendFunds {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
  padding-top: 20px;
}
.sendFundsMob {
  font-size: 19px;
  line-height: 22px;
}
.impsIcon {
  width: 80px;
  height: 30px;
  padding-top: 15px;
  padding-bottom: 30px;
}
.impsIconMob {
  padding-bottom: 20px;
}
.flexContent {
  display: flex;
}
.limitKey,
.feesKey,
.processingTimeKey {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #99b2c6;
}
.limitKeyMob,
.feesKeyMob,
.processingTimeKeyMob {
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: 370px) {
    font-size: 12px;
  }
}
.feesValue,
.limitValue,
.processingTimeValue {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding-left: 5px;
}
.feesValueMob,
.limitValueMob,
.processingTimeValueMob {
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: 370px) {
    font-size: 12px;
  }
  @media screen and (max-width: 325px) {
    font-size: 11px;
  }
}
.impsInfoContainer {
  border-top: 1px solid #99b2c6;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-bottom: 30px;
}
.continueButton {
  font-family: "Metrophobic";
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #99b2c6;
  padding: 10px 0px;
  border: 2px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  width: 50%;
}
.continueButton:hover {
  color: #fff;
}
.continueButtonMob {
  padding: 10px 0px;
  color: #fff;
  font-size: 20px;
}
.backButton {
  font-family: "Metrophobic";
  font-style: normal;
  font-weight: 400;
  color: #99b2c6;
  border: 1px solid rgb(111, 111, 111, 0.3);
  background: linear-gradient(180deg, #212c3d 0%, #101d2e 100%);
  cursor: pointer;
  border-radius: 4px;
  padding: 13px 0px;
  font-size: 21px;
  line-height: 26px;
  width: 50%;
  margin-right: 20px;
}
.backButton:hover {
  color: #fd5252;
}
.backButtonMob {
  font-size: 20px;
  padding: 10px 0px;
  color: #fd5252;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.buttonContainerMob {
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
}
